import React, { Component } from "react";

import HonorLevelInvestorList from './HonorLevelInvestorList.js';
import ProgressiveImage from './ProgressiveImage';
import fetchGoogleSheet from "../utils/fetchGoogleSheet";
import HeadTags from "./HeadTags";
import Loading from './Loading';

class Honor extends Component {

  constructor(props) {
    super(props);

    this.state = {
      investors: [],
      levels: [],
      query: '',
      hasFetchedData: false
    };
  }

  componentDidMount() {
    window.scrollTo(0,0);
    fetchGoogleSheet('1x-f3RnR6Ycnd3mqTheg9_-9m0u2jPSIIE27BzjYF15I', 'honor')
      .then(data => {
        const levels = data
          // First get unique values.
          .reduce((accumulator, currentValue) => {
            if (!accumulator.includes(currentValue.honormemoriam)) {
              accumulator.push(currentValue.honormemoriam);
            }
            return accumulator;
          }, [])
          // Then sort numerically in descending order.
          .sort()
          .reverse();

        const investorsSorted = data.sort((a, b) => {
          if (a.name_sort < b.name_sort) {
            return -1;
          }
          if (a.name_sort > b.name_sort) {
            return 1;
          }
          return 0;
        });

        this.setState({
          investors: investorsSorted,
          levels,
          hasFetchedData: true
        });
      });
  }

  _handleChange(e) {
    this.setState({ query: e.target.value.toLowerCase() });
  }

  _hasSearchResults() {
    return this.state.investors.filter(inv => inv.name_display.toLowerCase().includes(this.state.query) || inv.donors.toLowerCase().includes(this.state.query) ).length;
  }

  render() {
    return (
      <article className="list-page">
        <HeadTags
          pageTitle='In Honor & Memory | Columbia Business School Report to Investors 2019'
          metaTitle='Gifts Made in Honor & Memory 2019'
          metaDescription='The School thanks those who made contributions in honor and in memory of the family, friends, professors, and mentors who supported them during their time at Columbia, exemplifying the strength and generosity of our network.'
          metaImage='https://investors.gsb.columbia.edu/img/honor-2x.jpg'
          canonicalPath='/honor'
        />

        <h1>In Honor &amp; Memory</h1>
        <section className="intro">
          <ProgressiveImage
            name="honor"
            alt="Uris Hall"
          />
          <section className="intro-text">
          <p>Gifts made in honor or in memory of those special to us are a meaningful way to celebrate them and have a direct impact on the Business School community.</p>
          <p>Columbia Business School highlights contributions made in honor and in memory of individuals in our community. These gifts represent the spirit and generosity of our network.</p>
          </section>
        </section>

        <input
          type="text"
          className="search"
          placeholder="Search Gifts Made in Honor and Memory"
          onChange={this._handleChange.bind(this)}
        />

        {
          this.state.hasFetchedData ? (
            <div>
              <ul>
                {
                  this.state.levels.map((level, i) => (
                    <li key={i}>
                      <HonorLevelInvestorList
                        name={level}
                        investors={this.state.investors.filter(inv => inv.honormemoriam === level)}
                        query={this.state.query}
                      />
                    </li>
                  ))
                }
              </ul>
              {
                this.state.hasFetchedData && !this._hasSearchResults() ? (
                  <p>No results found</p>
                ) : false
              }
            </div>
          ) : (
            <Loading/>
          )
        }
      </article>
    );
  }
}

export default Honor;