import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link, NavLink } from "react-router-dom";
import $ from 'jquery';

import Home from './Home';
import Leadership from './Leadership.js';
import General from './General.js';
import Volunteers from './Volunteers.js';
import Honor from './Honor.js';

class App extends Component {

  componentDidMount() {
    $('.menu-link').click(function () {
      $('body').toggleClass('nav-active');
    });

    $('.main-nav').click(function () {
      if ($('body').hasClass('nav-active')) {
        $('body').removeClass('nav-active');
      }
    });
  }

  render() {
    return (
      <Router>
        <div>
          <header>
            <div className="header-wrap">
              <div className="logo-wrap-top">
                <Link to="/">
                  <img src="/images/ig-impact-logo.svg" width="250" height="42.25" alt="Columbia Business School Report to Investors 2020"/>
                </Link>
              </div>
              <div className="menu-link">
                <b></b>
                <b></b>
                <b></b>
              </div>
            </div>
          </header>

          <div className="page-wrap">
            <nav className="main-nav">
              <ul>
                <li>
                  <NavLink exact to="/">home</NavLink>
                </li>
                <li>
                  <NavLink exact to="/leadership">leadership giving</NavLink>
                </li>
                <li>
                  <NavLink exact to="/general">general support</NavLink>
                </li>
                <li>
                  <NavLink exact to="/volunteers">volunteers</NavLink>
                </li>
                <li>
                  <NavLink exact to="/honor">in honor & memory</NavLink>
                </li>
              </ul>
            </nav>

            <Route exact path="/" component={Home}/>
            <Route path="/leadership" component={Leadership}/>
            <Route path="/general" component={General}/>
            <Route path="/volunteers" component={Volunteers}/>
            <Route path="/honor" component={Honor}/>

          </div>

          <footer>
            <div className="footer-wrap">
              <div className="logo-wrap">
                <a href="https://www8.gsb.columbia.edu/">
                  <img
                    src="https://www8.gsb.columbia.edu/sites/all/themes/custom/gsb_themeV2/assets/images/logo/cbs-stacked-x240.svg"
                    alt="Columbia Business School"/>
                </a>
              </div>
              <div className="links-wrap">
                <ul>
                  <li><a target="_blank" rel="noopener noreferrer" href="//business.givenow.columbia.edu">Make a
                    Gift</a></li>
                  <li><a href="https://www8.gsb.columbia.edu/about-us">About Us</a></li>
                  <li><a href="https://www8.gsb.columbia.edu/cbs-directory">Directories</a></li>
                  <li><a href="https://www8.gsb.columbia.edu/rss-feeds">RSS Feeds</a></li>
                  <li><a href="https://www8.gsb.columbia.edu/about-us/contact-columbia/careers-columbia">Hiring</a></li>
                  <li><a href="https://www8.gsb.columbia.edu/newsroom/contact-us">Media Inquiries</a></li>
                  <li><a href="https://www8.gsb.columbia.edu/contact">Contact Us</a></li>
                  <li><a href="https://www8.gsb.columbia.edu/about-us/campus-maps-directions">Directions</a></li>
                </ul>
              </div>
              <div className="contact-wrap">
                <div className="social-links">
                  <ul>
                    <li>
                      <a href="https://twitter.com/Columbia_Biz" title="Follow on Twitter" target="_blank"
                         rel="noopener noreferrer">
                        <img height="30" width="30" src="/images/img-social-twitter.svg" alt="twitter"/>
                      </a>
                    </li>
                    <li>
                      <a href="https://instagram.com/columbia_biz" title="instagram" target="_blank"
                         rel="noopener noreferrer">
                        <img height="30" width="30" src="/images/img-social-instagram.svg" alt="instagram"/>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.facebook.com/columbiabusiness" title="Like on Facebook" target="_blank"
                         rel="noopener noreferrer">
                        <img height="30" width="30" src="/images/img-social-facebook.svg" alt="facebook"/>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/columbiabusiness" title="Follow on YouTube" target="_blank"
                         rel="noopener noreferrer">
                        <img height="30" width="30" src="/images/img-social-yt.svg" alt="You Tube"/>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/2625" title="Connect on LinkedIn" target="_blank"
                         rel="noopener noreferrer">
                        <img height="30" width="30" src="/images/img-social-linkedin.svg" alt="Linked In"/>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="contact">
                  <p>© 2021 <a target="_blank" rel="noopener noreferrer" href="https://www.columbia.edu/content/copyright">Columbia
                    University</a><br/>
                    Columbia University in the City of New York<br/>
                    3022 Broadway, New York, NY 10027<br/>
                    <a href="tel:+1-212-854-1100">+1-212-854-1100</a><br/>
                    <a target="_blank" rel="noopener noreferrer"
                       href="https://www8.gsb.columbia.edu/privacy-policy-statements">Privacy and Policy Statements</a>
                  </p>
                </div>
              </div>
            </div>
          </footer>
          <script type="text/javascript" src="//www.gsb.columbia.edu/cdn/js/privacyNotice/dist/js/privacy.js"></script>
        </div>
      </Router>
    );
  }
}

export default App;