import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

function HeadTags(props) {
  return (
    <Helmet>
      <title>{props.pageTitle}</title>
      <meta name="description" content={props.metaDescription} />
      <meta property="og:title" content={props.metaTitle} />
      <meta property="og:description" content={props.metaDescription} />
      <meta property="og:image" content={props.metaImage} />
      <meta name="twitter:title" content={props.metaTitle} />
      <meta name="twitter:description" content={props.metaDescription} />
      <meta name="twitter:image" content={props.metaImage} />
      <link rel="canonical" href={"https://investors.gsb.columbia.edu" + props.canonicalPath} />
      <script type="text/javascript" src="//www.gsb.columbia.edu/cdn/js/privacyNotice/dist/js/privacy.js"></script>
    </Helmet>
  )
}

HeadTags.propTypes = {
  pageTitle: PropTypes.string,
  metaTitle: PropTypes.string,
  metaDescription: PropTypes.string,
  metaImage: PropTypes.string,
  canonicalPath: PropTypes.string,
};

export default HeadTags;