import React, { Component }  from "react";
import PropTypes from 'prop-types';

class HonorLevelInvestorList extends Component {

  _getDonors(investor) {
    if (typeof investor.donors === 'undefined') { return []; }
    return investor.donors.split('|');
  }

  _getFilteredInvestors() {
    return this.props.investors
    // Filter by query, if one exists. Otherwise, do nothing.
      .filter(inv => {
        if (this.props.query.length) {
          return inv.name_display.toLowerCase().includes(this.props.query) || inv.donors.toLowerCase().includes(this.props.query)
        }
        return true;
      })
  }

  render() {
    const filteredInvestors = this._getFilteredInvestors();

    // Don't display the level if no investors meet the search query.
    if (!filteredInvestors.length) { return false; }

    return (
      <div className="list-section honor">
        <h2>{this.props.name}</h2>
        <ul>
          {
            filteredInvestors.map((inv, i) => {
              return (
                <li key={i} className="investor-item">
                  <div className="investor-item-inner">
                    <strong>{inv.name_display}</strong>
                    {
                      this._getDonors(inv).length ? (
                        this._getDonors(inv).length === 1 ? (
                          <ul className="gift-list">
                            <h3>gift made by</h3>
                            {
                              this._getDonors(inv).map((donor, i) => (
                                <li key={i}>{donor}</li>
                              ))
                            }
                          </ul>
                        ) :
                        <ul className="gift-list">
                          <h3>gifts made by</h3>
                          {
                            this._getDonors(inv).map((donor, i) => (
                              <li key={i}>{donor}</li>
                            ))
                          }
                        </ul>
                      ) : false
                    }
                  </div>
                </li>
              )
              })
          }
        </ul>
      </div>
    )
  }
}

HonorLevelInvestorList.propTypes = {
  name: PropTypes.string,
  investors: PropTypes.array,
  query: PropTypes.string,
};

export default HonorLevelInvestorList;