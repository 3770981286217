// Fetch data from google sheet using an API service that prettifies the response.
// Adapted from http://gsx2json.com/.
function fetchGoogleSheet(spreadsheetId, sheetId) {
  return new Promise((resolve, reject) => {
    return fetch('https://sheets.googleapis.com/v4/spreadsheets/' + spreadsheetId + '/values/' + sheetId + '?key=AIzaSyC2H40Lntt-vmlNb9N2tDHMEAcmz7DfvZk' )
      .then(resp => resp.json())
      .then(json => {
        const parsedResponse = parseGoogleSheetsResponse(json);
        resolve(parsedResponse.rows);
      })
      .catch(ex => {
        reject(ex);
      });
  });
}

function parseGoogleSheetsResponse(json) {
  var responseObj = {};
  var keys = json.values[0];
  var rows = [];
  var columns = {};
  for(var i = 1; i < json.values.length; i++) {
    var entry = json.values[i];
    var newRow = {};
    for(var j = 0; j < keys.length; j++) {
        var name = keys[j];
        var content = entry[j];
        newRow[name] = content;

        //if(!columns.hasOwnProperty(name)) {
        //  columns[name] = [];
        //  columns[name].push(value);
        //} else {
        //  columns[name].push(value);
        //}
    }
    rows.push(newRow);
  }
  responseObj['columns'] = columns;
  responseObj['rows'] = rows;
  return responseObj;
}

export default fetchGoogleSheet;