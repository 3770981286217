import React, { Component }  from "react";

import ImagesLoaded from 'react-images-loaded';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './index.css';

class ProgressiveImage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      imagesLoaded: false
    };
  }

  _handleImagesLoaded() {
    this.setState({ imagesLoaded: true });
  }

  render() {
    return (
      <section className={classNames('intro-image', { 'intro-image--images-loaded': this.state.imagesLoaded })}>
        <img
          className="progressive-image-placeholder"
          src={'/images/' + this.props.name + '-prog.jpg'}
          alt={this.props.alt}
        />

        <ImagesLoaded
          done={this._handleImagesLoaded.bind(this)}
        >
          <picture>
            <source
              type="image/jpg"
              srcSet={'/images/' + this.props.name + '-1x.jpg 1x, /images/' + this.props.name + '-2x.jpg 2x'}
            />

            <img src={'/images/' + this.props.name + '-1x.jpg'} alt={this.props.alt}/>
          </picture>
        </ImagesLoaded>
      </section>
    )
  }
}
ProgressiveImage.propTypes = {
  name: PropTypes.string,
  alt: PropTypes.string
};


export default ProgressiveImage;