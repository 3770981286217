import React from 'react';

import './index.css';

function Loading () {
  return (
    <div className="loading">
      <img src="/images/LogoLoopSquare.gif" alt="Loading"/>
    </div>
  )
}

export default Loading;