import React, { Component } from "react";

import LevelInvestorList from "./LevelInvestorList";
import ProgressiveImage from './ProgressiveImage';
import fetchGoogleSheet from '../utils/fetchGoogleSheet.js';
import HeadTags from "./HeadTags";
import Loading from './Loading';

class General extends Component {

  constructor(props) {
    super(props);

    this.state = {
      investors: [],
      levels: [],
      query: '',
      hasFetchedData: false
    };
  }

  componentDidMount() {
    window.scrollTo(0,0);
    fetchGoogleSheet('1x-f3RnR6Ycnd3mqTheg9_-9m0u2jPSIIE27BzjYF15I', 'General')
      .then(data => {
        const levels = data
          // First get unique values.
          .reduce((accumulator, currentValue) => {
            if (!accumulator.includes(currentValue.level)) {
              accumulator.push(currentValue.level);
            }
            return accumulator;
          }, [])
          // Then sort numerically in descending order.
          .sort((a, b) => {
            // Get the first number.
            const matchRegex = /^\$([\d,]+)/;
            const aMatch = a.match(matchRegex);
            const bMatch = b.match(matchRegex);
            // Remove the comma and convert to an integer. Finally, compare.
            const replaceRegex = /,/g;
            return parseInt(aMatch[1].replace(replaceRegex, '')) - parseInt(bMatch[1].replace(replaceRegex, ''));
          })
          .reverse();

        const investorsSorted = data.sort((a, b) => {
          if (a.name_sort.toLowerCase() === "anonymous" || a.name_sort.toLowerCase() < b.name_sort.toLowerCase()) {
            return -1;
          }
          if (a.name_sort.toLowerCase() > b.name_sort.toLowerCase()) {
            return 1;
          }
          return 0;
        });

        this.setState({
          investors: investorsSorted,
          levels,
          hasFetchedData: true
        });
      });
  }

  _handleChange(e) {
    this.setState({ query: e.target.value.toLowerCase() });
  }

  _hasSearchResults() {
    return this.state.investors.filter(inv => inv.name_display.toLowerCase().includes(this.state.query)).length;
  }

  render() {
    return (
      <article className="list-page">
        <HeadTags
          pageTitle='General Support | Columbia Business School Report to Investors 2020'
          metaTitle='General Support 2020'
          metaDescription='To sustain its culture of innovation, collaboration, and  academic rigor, Columbia Business School relies on the support of alumni, friends, students, faculty, and staff.'
          metaImage='https://investors.gsb.columbia.edu/img/general-2x.jpg'
          canonicalPath='/general'
        />

        <h1>General Support</h1>
        <section className="intro">
          <ProgressiveImage
            name="general"
            alt="Columbia investors"
          />

          <section className="intro-text">
          <p>Participatory gifts to Columbia Business School ensure the community’s preservation and endurance. Collectively, they help the School fulfill its mission by accommodating the tools and operations to study management. They reflect our community’s pride in and commitment to the School and society at large.</p>
          <p>Columbia Business School gratefully acknowledges the individuals and organizations that have provided a joint foundation through donations made between July 1, 2020 and June 30, 2021.</p>
          </section>
        </section>

        <input
          type="text"
          className="search"
          placeholder="Search General Support Investors"
          onChange={this._handleChange.bind(this)}
        />

        <p className="legend"><span className="nineteen">1916</span> <a href="https://www8.gsb.columbia.edu/alumni/support-school/annual-giving/1916-society" target="_blank" rel="noopener noreferrer">1916 Society</a></p> <p className="legend">&loz; Deceased</p>

        {
          this.state.hasFetchedData ? (
            <div>
              <ul>
                {
                  this.state.levels.map((level, i) => (
                    <li key={i}>
                      <LevelInvestorList
                        name={level}
                        investors={this.state.investors.filter(inv => inv.level === level)}
                        query={this.state.query}
                      />
                    </li>
                  ))
                }
              </ul>
              {
                this.state.hasFetchedData && !this._hasSearchResults() ? (
                  <p>No results found</p>
                ) : false
              }
            </div>
          ) : (
            <Loading/>
          )
        }
      </article>
    )
  }
}

export default General;