import React, { Component }  from "react";
import $ from 'jquery';
import Highcharts, { attr } from 'highcharts';

import jqueryAppear from '../../vendor/jquery.appear.js';

import ProgressiveImage from '../ProgressiveImage';
import HeadTags from '../HeadTags.js';

class Home extends Component {

  componentDidMount() {
    window.scrollTo(0,0);
    jqueryAppear($);

    this._initCounters();

    this._initHighCharts();
  }

  _initCounters() {
    $('.counter').each(function() {
      var $renderTo = $(this);
      // It is appeared, render it
      if ($renderTo.is(':appeared')) {
        animateCounter(this);

        // It is not appeared, halt rendering until appear
      } else  {
        $renderTo.appear(); // Initialize appear plugin
        $renderTo.on('appear', animateCounter.bind(null, this));
      }
    });

    function animateCounter(element) {
      var $this = $(element),
        countTo = $this.attr('data-count');

      $({ countNum: $this.attr('data-current-count')}).animate({
        countNum: countTo
      }, {
        duration: 4000,
        easing:'linear',
        step: function() {
          $this.text(commaSeparateNumber(Math.floor(this.countNum)));
          if($this.hasClass('dollars')){$this.append(' M')};
          $this.attr('data-current-count', (Math.floor(this.countNum)));
        },
        complete: function() {
          $this.text(commaSeparateNumber(this.countNum));
          if($this.hasClass('dollars')){$this.append(' M')};
          $this.attr('data-current-count', (Math.floor(this.countNum)));
        }
      });
    }

    function commaSeparateNumber(val) {
      while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d+)(\d{3})/, '$1,$2');
      };
        return val;
    }

  }

  _initHighCharts() {
    Highcharts.setOptions({
      colors: ['#355777','#974D60','#F16C80','#F58557','#F6B344','#99CC33','#79B895','#d8d8d8','#000'],
      chart : { style : { fontFamily: 'polaris,helvetica,sans-serif'}}
    });

    var mobileLegend = {
      width: 300,
      align:'center',
      itemMarginTop: 25,
      labelFormatter: function () {
        return '<div style="width:200px"><span style="float:left">$' + this.y.toFixed(2) + '<br></span><span style="float:right">' + this.name + '</span></div>';
      }
    };

    var titleCoords = { x: 0, y: -50 };
    var seriesSize = 250;
    var legend = mobileLegend;

    // Endowment Bar chart, doesn't change based on device
    drawBarChart('endowment-historical', function() {
      $('#endowment-historical').prepend('<figcaption><h2>FY 2021 Endowment</h2> <p class="note">in millions</p></figcaption>');
      $('text', '.highcharts-legend-item').attr('y', 27); //Correctly lines up legend item with legend symbol
    });

    // Endowment Breakdown Pie Chart
    drawPieChart('endowment-details', legend, generateTitle(titleCoords.x, titleCoords.y,'$961.93'), generateSeries(seriesSize, [
      ['Professorships ', 368.98],
      ['Centers ', 242.72],
      ['Unrestricted ', 109.11],
      ['Scholarships ', 145.60],
      ['Fellowships ', 72.39],
      ['Other ', 23.13]
    ]),
      function() {
        $('#endowment-details').prepend('<figcaption><h2>FY 2021 Endowment Breakdown</h2> <p class="note">in millions</p></figcaption>');
        //$('#endowment-details .highcharts-title tspan:first-child').text('$962');
        $('text', '.highcharts-legend-item').attr('y', 27); //Correctly lines up legend item with legend symbol
      }
    );
    
    // Operating Revenues Pie Chart
    drawPieChart('revenues', legend, generateTitle(titleCoords.x, titleCoords.y,'$261.36'), generateSeries(seriesSize, [
      ['Tuition and Fees ', 197.83],
      ['Endowment Income ', 27.96],
      ['Executive Education ', 21.66],
      ['Unrestricted Gifts', 13.91]
    ]),
      function() {
        $('#revenues').prepend('<figcaption><h2>FY2021 Operating Revenue</h2> <p class="note">in millions</p></figcaption>');
        // $('#revenues .highcharts-title tspan:first-child').attr('y',180).text('$261.36');
        $('text', '.highcharts-legend-item').attr('y', 27); //Correctly lines up legend item with legend symbol
      }
    );
    // Operating Expenses Pie Chart
    drawPieChart('expenses', legend, generateTitle(titleCoords.x, titleCoords.y,'$256.20'), generateSeries(seriesSize, [
      ['Faculty ', 81.95],
      ['Facilities ', 73.76],
      ['Administration ', 35.66],
      ['Alumni', 4.77],
      ['Ext. Relations', 6.82],
      ['Financial Aid ', 33.18],
      ['Student Services ', 11.07],
      ['I.T. ', 8.99]
    ]),
      function() {
        $('#expenses').prepend('<figcaption><h2>FY2021 Operating Expenses</h2> <p class="note">in millions</p></figcaption>');
        //$('#expenses .highcharts-title tspan:first-child').attr('y',190).text('$256.20');
        $('#expenses .highcharts-series-group').attr('y',217);
        $('text', '.highcharts-legend-item').attr('y', 27); //Correctly lines up legend item with legend symbol
      }
    );

    function generateSeries(size, data) {
      return [
        {
          size: size,
          type: 'pie',
          innerSize: '85%',
          dataLabels: {
            enabled: false
          },
          data: data
        }
      ]
    }

    function generateTitle(x, y, title) {
      return {
        text: title,
        align: 'center',
        verticalAlign: 'middle',
        y: y,
        x: x,
        style: {
          'fontSize': '2rem',
          'color': 'black',
          'fontWeight' : '700'
        }
      }
    }

    function drawPieChart(id, legend, title, series, onLoad) {
      let element = $('#' + id);
      if (element.is(':appeared')) {
        drawHighchart();
      } else {
        element.appear(); // Initialize appear plugin
        element.on('appear', drawHighchart);
      }

      function drawHighchart() {
        if (element.data('chart-has-appeared')) {
          return;
        }

        element.data('chart-has-appeared', true);

        new Highcharts.Chart({
          chart: {
            events: {
              load: onLoad
            },
            height: 560,
            renderTo: id,
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            marginTop:20,
          },
          tooltip: {
            pointFormat: '<b>{point.percentage:.1f}%</b>'
          },
          title: title,
          legend: legend,
          series: series,
          plotOptions: {
            pie: {
              point: {
                events: {
                  legendItemClick: function () {
                    return false;
                  }
                }
              },
              showInLegend: true,
              allowPointSelect: true,
              cursor: "pointer",
              dataLabels: {
                distance: 50,
                style: {
                  fontWeight: 'bold',
                  color: 'white',
                  textShadow: '0px 1px 2px black'
                }
              }
            },
            series: {
              animation: {
                duration: 500
              }
            }
          },
          credits: {
            enabled: false
          }
        });
      }
    }
    function drawBarChart(id, onLoad) {
      let element = $('#' + id);
      if (element.is(':appeared')) {
        drawHighchart();
      } else {
        element.appear(); // Initialize appear plugin
        element.on('appear', drawHighchart);
      }

      function drawHighchart() {
        if (element.data('chart-has-appeared')) {
          return;
        }

        element.data('chart-has-appeared', true);

        new Highcharts.Chart({
          chart: {
            events: {
              load: onLoad
            },
            height: 560,
            renderTo: id,
            type: 'column',
            
          },
          colors:["#88addb","#73a0d5","#5c95cf","#428ac9","#0081cc"],
          title: {
            text: null
          },
          legend: {
            enabled: false
          },
          plotOptions: {
            series: {
              animation: {
                duration: 500
              },
              dataLabels: {
                enabled: true,
                x: 0
              },
              colorByPoint: true,
              pointPadding: 0,
              groupPadding: 0
            }
          },
          credits: {
            enabled: false
          },
          tooltip: {
            enabled: false
          },
          xAxis: {
            categories: ['2017', '2018', '2019', '2020', '2021'],
            lineWidth: 0,
            lineColor: 'transparent',
            minorTickLength: 0,
            tickLength: 0,
            labels: {
              x: 0
            }
          },
          yAxis: {
            gridLineColor: 'transparent',
            labels: {
              enabled: false
            },
            title: {
              text: null
            }
          },
          series: [{
            data: [707.12, 741.49, 750.49, 755.92, 961.93]
          }]
        });
      }
    }
  }

  render() {
    return (
      <article className="home">
        <HeadTags
          pageTitle='Columbia Business School Report to Investors 2020'
          metaTitle='Report to Investors 2020'
          metaDescription='Thank you to all of our donors and volunteers for your critical support in the 2019–2020 school year.'
          metaImage='https://investors.gsb.columbia.edu/img/maglaras-2x.jpg'
          canonicalPath='/'
        />

        <h1>Thank you</h1>
        <section className="intro">
          <ProgressiveImage
            name="maglaras"
            alt="Dean Costis Maglaras"
          />
        <section className="intro-text">

       <p> As Columbia Business School welcomes back students and faculty for the fall semester of the 2021-2022 Academic Year, I want to share with you the sense of excitement and optimism that pervades our campus at this milestone moment in our history, a moment in which you are a critical part. CBS, which is now fully open to in-person teaching and learning, recently welcomed the largest incoming MBA cohort in its history—614 students hailing from six continents, 27 states, and 61 countries. We also welcomed 144 EMBA students and 24 new PhD students. Because of the ongoing financial support and commitment from donors like you, CBS is well-positioned to meet the evolving demands that the global economy has placed on graduate business education. </p>

<p>The 2020-2021 Academic Year was also a milestone for our community. More than 7,300 donors marked their commitment to CBS, contributing countless hours of their time, as well as a record-breaking $107 million in gifts. These gifts focused on increasing student financial aid, catalyzing faculty research, developing new state-of-the-art courses, expanding Alumni Edge offerings, and underwriting construction on our Manhattanville campus, including the landmark gift of $75 million made through the David Geffen Foundation. In addition, your support for our centers and programs continues to advance our initiatives in social enterprise, entrepreneurship, ethical leadership, and business strategy.</p>

<p>As we look ahead, it’s clear that we are entering an ambitious new era for CBS, its impact, and its legacy. The world of business is being disrupted, and CBS is leading through this period of change. Through research, scholarship, and curricular innovation, we are preparing our students to navigate the most critical shifts facing society today, including technology and digital transformation, climate change and business, and more. We’re also moving to our incredible new home on our Manhattanville campus, where Henry R. Kravis Hall and David Geffen Hall will bring our emphasis on collaboration, innovation, interaction, and community to life.  </p>

<p>I am so proud of what we have accomplished together, and I look forward to what we
will continue to achieve as a community in the year ahead.</p>


          <p>All my best,</p>

          <p><strong>Dean Costis Maglaras</strong><br/>David and Lyn Silfen Professor of Business</p>
        </section>
        </section>

        <section className="centered-button">
          <div>
            <a href="https://www8.gsb.columbia.edu/alumni/support-school/donor-profiles" className="button">View Donor Profiles &nbsp; &#8594;</a>
          </div>
        </section>

        <span className="section-title"><h2>Students</h2></span>
        <section className="student-images">
            <figure className="stude-img-container">
              <div className="stude-img">
                <ProgressiveImage name="student-image-1" alt="student sitting on grass"/>
              </div>
              <figcaption className="stude-img-caption">
                  <div className="stude-img-caption-icon"><img src="/images/scholarship.svg" width="48" height="48" alt="scholarship icon" /></div>
                  <div className="stude-img-caption-text"><span className="headline-number">608</span><br/>MBA awardees for scholarship and fellowships</div>
              </figcaption>
            </figure>
            <figure className="stude-img-container">
              <div className="stude-img">
                <ProgressiveImage name="student-image-2" alt="masked students in lecture"/>
              </div>
              <figcaption  className="stude-img-caption">
                  <div className="stude-img-caption-icon"><img src="/images/research.svg" width="48" height="48" alt="scholarship icon" /></div>
                  <div className="stude-img-caption-text"><span className="headline-number">98%</span><br/>Students who applied for and received summer internship offers</div>
              </figcaption>
            </figure>
            <figure className="stude-img-container">
              <div className="stude-img">
                <ProgressiveImage name="student-image-3" alt="masked students in lecture"/>
              </div>
              <figcaption className="stude-img-caption">
                  <div className="stude-img-caption-icon"><img src="/images/sunflower.svg" width="48" height="48" alt="scholarship icon" /></div>
                  <div className="stude-img-caption-text"><span className="headline-number">$22.9M</span><br/>Total financial aid</div>
              </figcaption>
            </figure>
        </section>

        <section className="centered-button">
          <div>
            <a href=" https://www8.gsb.columbia.edu/graduation/ceremony/awards" className="button">2021 Class Awards &nbsp; &#8594;</a>
          </div>
        </section>

        <span className="section-title"><h2>Alumni</h2></span>
        <section className="student-images">
            <figure className="stude-img-container">
              <div className="stude-img">
                <ProgressiveImage name="alumni-image-1" alt="students wearing graduation gowns"/>
              </div>
              <figcaption className="stude-img-caption">
                  <div className="stude-img-caption-icon"><img src="/images/hands.svg" width="48" height="48" alt="scholarship icon" /></div>
                  <div className="stude-img-caption-text"><span className="headline-number">94%</span><br/>New alumni who received job offers within 3 mos. of graduating</div>
              </figcaption>
            </figure>
            <figure className="stude-img-container">
              <div className="stude-img">
                <ProgressiveImage name="alumni-image-3" alt="students at social event"/>
              </div>
              <figcaption className="stude-img-caption">
                  <div className="stude-img-caption-icon"><img src="/images/volunteer.svg" width="48" height="48" alt="scholarship icon" /></div>
                  <div className="stude-img-caption-text"><span className="headline-number">1,641</span><br/>Alumni volunteers</div>
              </figcaption>
            </figure>
            <figure className="stude-img-container">
              <div className="stude-img">
                <ProgressiveImage name="alumni-image-2" alt="Dean Maglaras with 2 alumni"/>
              </div>
              <figcaption className="stude-img-caption">
                  <div className="stude-img-caption-icon"><img src="/images/computer.svg" width="48" height="48" alt="scholarship icon" /></div>
                  <div className="stude-img-caption-text" id="final-caption"><span className="headline-number">6,306</span><br/>Alumni donors</div>
              </figcaption>
            </figure>
        </section>

        <section className="learnmore">
        <span className="learnmore-title"><h2>Learn More</h2></span>
        <p className="learnmore-link"><a href="https://www8.gsb.columbia.edu/about-us/diversity-equity-inclusion" target="_blank" rel="noopener noreferrer">The Diversity, Equity, and Inclusion Initiative &nbsp; &#8594;</a></p>
        <p className="learnmore-link"><a href="https://leading.gsb.columbia.edu/" target="_blank" rel="noopener noreferrer">Thought Leadership &nbsp; &#8594;</a></p>
        <p className="learnmore-link"><a href="https://www8.gsb.columbia.edu/socialenterprise/research/climateconference/2021" target="_blank" rel="noopener noreferrer">2021 Climate Science & Investment Conference &nbsp; &#8594;</a></p>
        <p className="learnmore-link"><a href="https://www8.gsb.columbia.edu/entrepreneurship/" target="_blank" rel="noopener noreferrer">Entrepreneurship and Innovation &nbsp; &#8594;</a></p>
        </section>

        <br/><br/>

        <section className="financials">
          <section className="at-a-glance">
            <h1>2021 Financials at a Glance</h1>
            <figure className="participation-2018">
              <div className="counter" data-count="7382">0</div>
              <figcaption>
                <p>Total donors</p>
              </figcaption>
            </figure>
            <figure className="participation-total">
              <div className="counter" data-count="57">0</div>
              <figcaption>
                <p>Countries represented</p>
              </figcaption>
            </figure>
            <figure className="endowment-2017">
              <div className="counter dollars" data-count="107">0</div>
              <figcaption>
                <p>Total gifts</p>
              </figcaption>
            </figure>
          </section>
          <section className="financial-details">
            <div className="figure-container">
              <figure id="endowment-historical" data-appear-top-offset="-200">
                <figcaption>
                  <h2>FY 2021 Endowment</h2>
                  <p className="note">in millions</p>
                </figcaption>
              </figure>
            </div>

            <div className="figure-container">
              <figure id="endowment-details" data-appear-top-offset="-200">
                <figcaption>
                  <h2>FY 2021 Endowment Breakdown</h2>
                  <p className="note">in millions</p>
                </figcaption>
              </figure>
            </div>

            <div className="figure-container">
              <figure id="revenues" data-appear-top-offset="-200">
                <figcaption>
                  <h2>FY 2021 Operating Revenues</h2>
                  <p className="note">in millions</p>
                </figcaption>
              </figure>
            </div>

            <div className="figure-container">
              <figure id="expenses" data-appear-top-offset="-200">
                <figcaption>
                  <h2>FY 2021 Operating Expenses</h2>
                  <p className="note">in millions</p>
                </figcaption>
              </figure>
            </div>

          </section>
        </section>

      </article>
    );
  }
}

export default Home;