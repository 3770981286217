import React, { Component }  from "react";

import ProgressiveImage from './ProgressiveImage';
import fetchGoogleSheet from '../utils/fetchGoogleSheet.js';
import HeadTags from "./HeadTags";
import Loading from './Loading';

class Volunteers extends Component {

  constructor(props) {
    super(props);

    this.state = {
      investors: [],
      query: '',
      hasFetchedData: false
    };
  }

  componentDidMount() {
    window.scrollTo(0,0);
    fetchGoogleSheet('1x-f3RnR6Ycnd3mqTheg9_-9m0u2jPSIIE27BzjYF15I', 'Volunteers')
      .then(data => {
        const investorsSorted = data.sort((a, b) => {
          if (a.name_sort < b.name_sort) {
            return -1;
          }
          if (a.name_sort > b.name_sort) {
            return 1;
          }
          return 0;
        });

        this.setState({
          investors: investorsSorted,
          hasFetchedData: true
        });
      });
  }

  _handleChange(e) {
    this.setState({ query: e.target.value.toLowerCase() });
  }

  _hasSearchResults() {
    return this.state.investors.filter(inv => inv.name_display.toLowerCase().includes(this.state.query)).length;
  }

  render() {
    return (
      <article className="list-page volunteers">
        <HeadTags
          pageTitle='Volunteers | Columbia Business School Report to Investors 2020'
          metaTitle='Volunteers 2020'
          metaDescription='Each year, thousands of Columbia Business School alumni, students and friends contribute their time, energy, and expertise to support the School and its crucial mission of impact on business and society.'
          metaImage='https://investors.gsb.columbia.edu/img/volunteers-2x.jpg'
          canonicalPath='/volunteers'
        />

        <h1>Volunteers</h1>
        <section className="intro">
          <ProgressiveImage
            name="volunteers"
            alt="Columbia volunteers"
          />

          <section className="intro-text">
          <p>The Business School has a phenomenal pool of individuals who are integral to organizing and executing events, programs, and other synergistic activities. Each year, dedicated alumni, students, and friends donate their time to serve the community. Volunteers help teach classes, design centers and programs, share their expertise on industry panels, and mentor students. </p>
          <p>Columbia Business School deeply values the members of our community who volunteered during the 2020–2021 academic year.</p>
          <p>
          <a className="button" href="https://www8.gsb.columbia.edu/alumni/volunteer/">Get Involved&nbsp;&rarr;</a>
          </p>
          <p>
          <a className="button" href="https://www8.gsb.columbia.edu/about-us/board">The Board&nbsp;&rarr;</a>
          </p>
          </section>
        </section>

        <input
          type="text"
          className="search"
          placeholder="Search Volunteers"
          onChange={this._handleChange.bind(this)}
        />

        {
          this.state.hasFetchedData ? (
            <div>
              <ul>
                {
                  this.state.investors
                    .filter(inv => {
                      // Filter by query, if one exists. Otherwise, do nothing.
                      if (this.state.query.length) {
                        return inv.name_display.toLowerCase().includes(this.state.query)
                      }
                      return true;
                    })
                    .map((inv, i) => (
                      <li key={i} className="investor-item">
                        <div className="investor-item-inner">
                          {inv.name_display}
                          {
                            inv.nineteen === '1' ? (<span className="nineteen">1916</span>) : false
                          }
                        </div>
                      </li>
                    ))
                }
              </ul>
              {
                this.state.hasFetchedData && !this._hasSearchResults() ? (
                  <p>No results found</p>
                ) : false
              }
            </div>
          ) : (
            <Loading/>
          )
        }
      </article>
    );
  }
}

export default Volunteers;