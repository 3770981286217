// Add polyfills
import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch'
import 'core-js/fn/string/includes';
import 'core-js/fn/array/includes';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';

import './scss/main.scss';

ReactDOM.render(<App />, document.getElementById('root'));