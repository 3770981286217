import React, { Component }  from "react";
import PropTypes from 'prop-types';

class LevelInvestorList extends Component {

  _getFilteredInvestors() {
    return this.props.investors
      // Filter by query, if one exists. Otherwise, do nothing.
      .filter(inv => {
        if (this.props.query.length) {
          return inv.name_display.toLowerCase().includes(this.props.query)
        }
        return true;
      })
  }

  render() {
    const filteredInvestors = this._getFilteredInvestors();

    // Don't display the level if no investors meet the search query.
    if (!filteredInvestors.length) { return false; }

    return (
      <div className="list-section">
        <h2>{this.props.name}</h2>
        <ul>
          {

            filteredInvestors.map((inv, i) => (
              <li key={i} className="investor-item">
                <div className="investor-item-inner">
                  {inv.name_display}
                  {
                    inv.deceased === '1' ? (<span title="deceased" className="deceased">&loz;</span>) : false
                  }
                  {
                    inv.nineteen === '1' ? (<span title="1916 Society Member" className="nineteen">1916</span>) : false
                  }
                </div>
              </li>
            ))
          }
        </ul>
      </div>
    )
  }
}

LevelInvestorList.propTypes = {
  name: PropTypes.string,
  investors: PropTypes.array,
  query: PropTypes.string,
};

export default LevelInvestorList;